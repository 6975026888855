export const maximumUsers = {
    active: false,
    maxUsers: 28
};

export const excelFile = false

export const firstLevel = false

export const roleNameArabic = false

export const BeCountrysideOrCity = false