<template>
    <div>
        <!-- Toggle employee account status dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="500"
            ref="dialogToggle"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <div 
                        class="d-flex py-1 "
                        :class="getEmployeeById(employeeId).isDeactivated ? 'success' : 'error lighten-2'"
                    >
                        <v-card-title
                        	class="text-uppercase text-subtitle-1 flex-grow-1 justify-center pa-0 white--text"
                        >
                            Toggle employee account status
                        </v-card-title>
                        <!-- close button -->
                        <v-btn icon @click="dialog.value = false">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-card-text class="text-body-1 pb-0 pt-2">
                        Are you sure you want to
                        <span 
                            class="text--lighten-1" 
                            :class="getEmployeeById(employeeId).isDeactivated ? 'success--text' : 'error--text text--lighten-2'"
                        >
                            {{getEmployeeById(employeeId).isDeactivated ? 'Activated' : 'Deactivated'}}
                        </span>
                        the selected employee's account?
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            :loading="submitLoading"
                            :color="getEmployeeById(employeeId).isDeactivated ? 'success' : 'red lighten-1'"
                            class="white--text"
                            text
                            @click="submitToggle"
                        >
                            Confirm
                        </v-btn>
                        <v-btn
                            text
                            :disabled="submitLoading"
                            @click="dialog.value = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- employee-dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="670"
            v-model="medicalRepDialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex justify-end py-1 background-title-card ">
                            <v-card-title
                            	class="text-uppercase text-subtitle-1 flex-grow-1 justify-center w-100 pa-0 white--text"
                            >
                                {{ !employeeId ? 'Add employee' : 'Edit employee' }}
                            </v-card-title>
                            <v-btn icon @click="dialog.value = false;">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>

                        <v-card-text class="pa-0">
                            <!-- dialog content -->
                            <v-row class="ma-0 text-body-1 grey--text text--darken-4">
                                <v-col sm="6" cols="12" class="px-4 pb-0">
                                    <div class="mb-1">Personal information</div>

                                    <!-- employee photo -->
                                    <div class="d-flex justify-center pb-0">
                                        <div class="personal-image">
                                            <v-img
                                                height="100"
                                                max-height="100"
                                                width="100"
                                                max-width="100"
                                                class="rounded-circle"
                                                :src="previewPhoto || medicalRepImagePath || require(
                                                    employeeData.gender === gender.male
                                                        ? '@/assets/male.jpg'
                                                        : '@/assets/avatar-10-vector-37332908.jpg'
                                                )"
                                                :gradient="employeePhoto ? '#eee2, #eee7, #eee2' : null"
                                            ></v-img>
                                            
                                            <div class="personal-image__actions">
                                                <v-btn
                                                    v-if="employeePhoto !== null"
                                                	icon
                                                	width="30"
                                                	height="30"
                                                	min-width="0"
                                                	color="red lighten-1"
                                                	@click="previewPhoto = null; employeePhoto = null"
                                                >
                                                    <v-icon> mdi-delete </v-icon>
                                                </v-btn>

                                                <v-file-input
                                                    v-model="employeePhoto"
                                                    class="ma-0 pa-0"
                                                    :class="{ 
                                                        'ms-10 mt-7': employeePhoto === null,
                                                    }"
                                                    accept="image/*"
                                                    label="employee photo"
                                                    placeholder="Pick a photo"
                                                    :prepend-icon="employeePhoto !== null ? 'mdi-image' : 'mdi-plus-circle'"
                                                    hide-input
                                                    @change="onImageChange"
                                                ></v-file-input>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- employee name -->
                                    <v-text-field
                                        label="Full name"
                                        clearable
                                        v-model="employeeData.name"
                                        :rules="rules.required"
                                        required
                                        outlined
                                        dense
                                        prepend-icon="mdi-account"
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- employee name -->
                                    <v-text-field
                                        v-if="firstLevel"
                                        v-model="employeeData.certificate"
                                        label="Certificate"
                                        clearable
                                        required
                                        outlined
                                        dense
                                        prepend-icon="mdi-file-certificate "
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- employee birthday date -->
                                    <bee-date-picker
                                        v-model="employeeData.birthDate"
                                        input-label="Birthday"
                                        menu-picker
                                        dense
                                        class="mb-1"
                                        hide-details
                                        :active-picker.sync="activePicker"
                                        :input-props="{
                                            readonly: true,
                                            outlined: true,
                                            dense: true,
                                            clearable: true,
                                            prependIcon: 'mdi-calendar'
                                        }"
       
                                    ></bee-date-picker>

                                    <!-- gender -->
                                    <v-radio-group
                                        v-model="employeeData.gender"
                                        hide-details
                                        row
                                        dense
                                        class="mb-1 mt-0 pt-0"
                                    >
                                        <span class="me-1">Gender:</span>
                                        <v-radio
                                            v-for="gender in genders"
                                            :key="gender.id"
                                            :label="gender.name"
                                            :value="gender.id"
                                            dense
                                            hide-details
                                            class="ma-0 me-2"
                                        ></v-radio>
                                    </v-radio-group>

                                    <!--  Contact information section -->
                                    <div class="mb-1">Contact information</div>

                                    <!-- Phone number -->
                                    <v-text-field
                                        v-model="employeeData.phoneNumber"
                                        label="Phone number"
                                        prepend-icon="mdi-phone"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>
                                    
                                    <!-- Telephone number -->
                                    <v-text-field
                                        label="Telephone number"
                                        v-model="employeeData.landlineNumber"
                                        clearable
                                        outlined
                                        dense
                                        prepend-icon="mdi-phone-classic"
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- full address -->
                                    <v-text-field
                                        label="Full address"
                                        v-model="employeeData.address"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        prepend-icon="mdi-map-marker"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- email -->
                                    <v-text-field
                                        label="Email"
                                        v-model="employeeData.email"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        prepend-icon="mdi-email"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- facebook profile url -->
                                    <v-text-field
                                        label="Facebook profile Url"
                                        v-model="employeeData.facebookProfileUrl"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        prepend-icon="mdi-facebook"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- work information section -->
                                    <div class="mb-1">Work information</div>
                                        
                                    <!-- team -->
                                    <v-autocomplete 
                                        v-model="employeeData.teamId" 
                                        :items="teams"
                                        item-text="name"
                                        item-value="id"
                                        hide-details
                                        dense
                                        label="Team name"
                                        outlined
                                        multiple
                                        :search-input.sync="searchTeamDialog" 
                                        @change="searchTeamDialog = null"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2" />

                                <v-col sm="6" cols="12" class="px-4 pb-0 pt-sm-3 pt-0">

                                    <!-- userType -->
                                    <v-radio-group
                                        v-model="employeeData.userType"
                                        hide-details
                                        row
                                        dense
                                        class="mb-1 mt-0 pt-0"
                                    >
                                        <span class="me-1">Type:</span>
                                        <v-radio
                                            v-for="userType in userTypes"
                                            :key="userType.id"
                                            :label="userType.name"
                                            :value="userType.id"
                                            dense
                                            hide-details
                                            class="ma-0 me-2"
                                        ></v-radio>
                                    </v-radio-group>

                                    <!-- Sector -->
                                    <v-autocomplete
                                        label="Areas"
                                        v-model="employeeData.sectorId"
                                        clearable
                                        :items="sectorsMine"
                                        item-value="id"
                                        item-text="name"
                                        :return-object="false"
                                        :loading="sectorsLoading"
                                        :disabled="sectorsLoading"
                                        :rules="rules.required"
                                        outlined
                                        dense
                                        prepend-icon="mdi-set-center"
                                        hide-details="auto"
                                        class="mb-1"
                                        @click:clear="employeeData.cityId = []; employeeData.regionIds = [] "
                                        @change="getCities()"
                                    ></v-autocomplete>

                                    <!-- City -->
                                    <v-autocomplete
                                        label="City"
                                        v-model="employeeData.cityId"
                                        :items="employeeData.sectorId === null ? cities : citiesBySectorFilter"
                                        item-value="id"
                                        item-text="name"
                                        prepend-icon="mdi-city"
                                        hint="Enter city to filter areas"
                                        persistent-hint
                                        clearable
                                        outlined
                                        dense
                                        :rules="rules.required"
                                        hide-details="auto"
                                        class="mb-1"
                                        @click:clear="employeeData.regionIds = []"
                                        multiple
                                        @change="employeeData.regionIds = []; searchCityIdDialog = null"
                                        :search-input.sync="searchCityIdDialog" 
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ employeeData.cityId.length - 1 }})
                                            </span>
                                        </template>

                                        <template v-slot:prepend-item>
                                            <v-list-item ripple @click="toggleCitiesDialog">
                                                <v-list-item-action>
                                                    <v-icon color='indigo darken-4'>{{ iconCitiesDialog }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>Select All</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider />
                                        </template>
                                    </v-autocomplete>
    
                                    <v-autocomplete
                                        label="Regions"
                                        v-model="employeeData.regionIds"
                                        clearable
                                        :items="employeeData.cityId ? regionsAll.filter(c => employeeData.cityId.includes(c.cityId)) : regionsAll"
                                        item-value="id"
                                        item-text="name"
                                        :rules="rules.required"
                                        :loading="sectorsLoading"
                                        :disabled="sectorsLoading"
                                        required
                                        outlined
                                        dense
                                        prepend-icon="mdi-set-center"
                                        hide-details="auto"
                                        class="mb-1"
                                        multiple
                                        :search-input.sync="searchRegionDialog" 
                                        @change="searchRegionDialog = null"
                                    >

                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ employeeData.regionIds.length - 1 }})
                                            </span>
                                        </template>

                                        <template v-slot:prepend-item>
                                            <v-list-item ripple @click="toggleRegion">
                                                <v-list-item-action>
                                                    <v-icon color='indigo darken-4'>{{ iconRegions }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>Select All</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider />
                                        </template>
                                    </v-autocomplete>
                                    
                                    <!-- job position -->
                                    <v-autocomplete
                                        v-model="employeeData.roleId"
                                        :items="myRoles"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        label="Role"
                                        outlined
                                        dense
                                        :rules="rules.required"
                                        prepend-icon="mdi-account"
                                        hide-details="auto"
                                        class="mb-1"
                                    />

                                    <v-autocomplete
                                        v-model="employeeData.specialtyIds"
                                        :items="specialties"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        hide-details
                                        outlined
                                        label="Specialties"
                                        prepend-icon="mdi-medical-bag"
                                        multiple
                                        class="mb-1"
                                        :search-input.sync="searchSpecialtyIdsDialog" 
                                        @change="searchSpecialtyIdsDialog = null"
                                        :disabled="employeeData.teamId.length !== 0"
                                    >

                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                            <span
                                                v-if="index === 1"
                                                class="grey--text text-caption"
                                            >
                                                (+{{ employeeData.specialtyIds.length - 1 }})
                                            </span>
                                        </template>

                                        <template v-slot:prepend-item>
                                            <v-list-item ripple @click="toggleSpecialty">
                                                <v-list-item-action>
                                                    <v-icon color='indigo darken-4'>{{ iconSpecialty }}</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>Select All</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider />
                                        </template>
                                    </v-autocomplete>

                                    <!-- target for medical rep -->
                                    <v-text-field
                                        v-if="employeeData.userType === userType.medicalRep"
                                        v-model.number="employeeData.targetValue"
                                        label="target"
                                        clearable
                                        outlined
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        prepend-icon="mdi-target"
                                        hide-spin-buttons
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- Number of vacation days employee -->
                                    <v-text-field
                                        v-model.number="employeeData.leaveDaysNumber"
                                        label="Number of vacation days"
                                        clearable
                                        outlined
                                        dense
    
                                        prepend-icon="mdi-calendar-remove"
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- employee starting date -->
                                    <bee-date-picker
                                        v-model="employeeData.startingDate"
                                        input-label="Hiring date"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true,
                                            prependIcon: 'mdi-calendar'
                                        }"
                                        class="mb-1"

                                    ></bee-date-picker>

                                    <!-- mobile app account -->
                                    <div class="mb-1">Mobile app account</div>
                                    <v-btn 
                                        @click="generate()" 
                                        small 
                                        color="success"
                                        style="width: 100%"
                                        class="mb-2"
                                        :disabled="employeeData.name === null"
                                    > 
                                        Generation
                                    </v-btn>
                                    <!-- Username on app -->
                                    <v-text-field
                                        label="Username on app"
                                        v-model="employeeData.username"
                                        :rules="rules.required"
                                        clearable
                                        required
                                        outlined
                                        dense
    
                                        prepend-icon="mdi-account-circle"
                                        hide-details="auto"
                                        class="mb-1"
                                    ></v-text-field>

                                    <!-- Password on app -->
                                    <v-text-field
                                        label="Password on app"
                                        v-model="employeeData.password"
                                        :rules="employeeId ? []: rules.required.concat(rules.password)"
                                        :hint="employeeId ? 'Leave it empty if you don\'t want to change' : ''"
                                        persistent-hint
                                        clearable
                                        required
                                        outlined
                                        dense
    
                                        prepend-icon="mdi-lock"
                                        hide-details="auto"
                                        class="mb-1"
                                        @click:append="showPassword = !showPassword"
                                        :type="showPassword ? 'text' : 'password'"
							            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                text
                                color="success white--text"
                                :loading="submitLoading"
                            >
                                save
                            </v-btn>
                            <v-btn
                                text
                                :disabled="submitLoading"
                                @click="dialog.value = false;"
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- section two add employee -->
        <v-dialog v-model="managerDialog" width="650">
            <v-form @submit.prevent="updateLineManager" ref="formTwo">
                <v-card>
                    <div class="d-flex justify-end py-1 background-title-card ">
                        <v-card-title
                            class="text-uppercase text-subtitle-1 flex-grow-1 justify-center w-100 pa-0 white--text"
                        >   
                            Choosing the manager and the employees he supervises
                        </v-card-title>
                        <v-btn icon @click="managerDialog = false;">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text>
                        <v-autocomplete
                            v-model="lineManagerId"
                            :items="lineManagers"
                            item-text="name"
                            item-value="id"
                            label="Line manager"
                            dense
                            hide-details
                            outlined
                            prepend-icon="mdi-account-tie"
                            :rules="rules.required"
                        />

                        <v-card class="scroll-style mb-4" elevation="0">
                            <v-card-title class="fs-1rem">
                                <span>
                                    Choose the employees who will be their supervisor
                                </span>
                            </v-card-title>
                            <v-card-text >
                                <div v-if="directBriefEmployee.length !== 0" class="d-flex flex-wrap">
                                    <div
                                        v-for="(r, index) in directBriefEmployee"
                                        :key="index"
                                        class="mx-2 "
                                        style="width: 200px"
                                    >
                                        <v-checkbox 
                                            v-model="r.model"
                                            :value="r.id"
                                            :label="r.name"
                                            dense
                                            hide-details
                                        />
                                    </div>
                                </div>
                                <div v-else class="text-center">
                                    <v-icon> mdi-data </v-icon>                        
                                    <span> Not found data </span>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn
                            type="submit"
                            text
                            color="success white--text"
                            :loading="submitLoading"
                        >
                            save
                        </v-btn>
                        <v-btn
                            text
                            :disabled="submitLoading"
                            @click="managerDialog = false;"
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- delete dialog -->
        <delete-dialog
        	ref="deleteDialog"
        	@on-delete="filter()"
        	success-message="Data is deleted"
        >
            Are you sure you want to <span class="red--text">delete</span> 
            employee account?
        </delete-dialog>
        <v-row :class="{ 'mt-1':  permissionsUser.Roles === roleName.nothing}">
			<v-col 
                align-self="center" 
                class="d-flex"
                :class="{
                    'justify-end': isSuperAdmin || permissionsUser.Roles === roleName.action
                }" 
            > 
                <!-- top side -->
                <!-- add employee -->
                <v-btn
                    v-if="
                        !isEmployeeActionsPrevented  && (
                            isSuperAdmin ||
                            (
                                permissionsUser.MedicalReps === roleName.action &&
                                permissionsUser.Roles !== roleName.nothing
                            )
                        )
                    "
                    small
                    class="text-center mx-2"
                    color="light-blue white--text"
                    @click="addNewMedicalRep"
                >
                    <v-icon size="22" class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    add employee
                </v-btn>

                <v-btn
                    v-if="isSuperAdmin || permissionsUser.Roles !== roleName.nothing"
                    small
                    class=" text-center mx-2"
                    color="light-blue white--text"
                    :to="{
                        name: 'Roles'
                    }"
                >
                    <v-icon size="22" class="me-2">
                        mdi-cog-sync
                    </v-icon>
                    manage roles
                </v-btn>

                <v-btn
                    v-if="isSuperAdmin || permissionsUser.ManageTeam !== roleName.nothing"
                    small
                    class=" text-center mx-2"
                    color="light-blue white--text"
                    :to="{
                        name: 'teams'
                    }"
                >
                    <v-icon size="22" class="me-2">
                        mdi-cog-sync
                    </v-icon>
                    manage team
                </v-btn>
            </v-col>
        </v-row>

        <template v-if="permissionsUser.Roles !== roleName.nothing" >
            <v-tabs v-model="tabRole">
                <v-tab
                    v-for="(tab, index) in roles.map(c => ({ name: c.name, id: c.id }))"
                    :key="index"
                    class="text-capitalize"
                    @change="name = null; getEmployeeForRole(tab.id)"
                    :disabled="loading"
                >
                    {{ tab.name }}
                </v-tab>
                <v-tabs-slider color="primary"></v-tabs-slider>
            </v-tabs>
            <v-divider class="mx-1"/>
        </template>
            
        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; filter();">
            <v-row class="mx-1 mb-1 mt-2" align="center">
                <!-- city region filter -->
                <v-col lg="4" md="5" sm="6" cols="12" class="pa-0 ">
                    <v-row class="pa-3">
                        <!-- city name -->
                        <v-col cols="5" class="pa-0 pe-1 d-flex" >
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="City"
                                @change="fetchRegionsDueCity"
                                dense
                                hide-details
                                append-icon
                                :disabled="loading"
                                outlined
                                prepend-icon="mdi-map-marker-multiple"
                                multiple
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleCities"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconCities }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ cityId.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
            
                        <!-- region -->
                        <v-col cols="7" class="pa-0">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => cityId.includes(c.cityId))"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                label="Regions"
                                dense
                                multiple
                                hide-details
                                clearable
                                :loading="regionsLoading"
                                :disabled="regionsLoading || cityId === []"
                                outlined
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggleRegionFilter">
                                        <v-list-item-action>
                                            <v-icon color='indigo darken-4'>{{ iconRegionsFilter }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>Select All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                
                <!-- team -->
                <v-col cols="2">
                    <v-autocomplete
                        v-model="teamIds"
                        :items="teams"
                        item-text="name"
                        item-value="id"
                        multiple
                        dense
                        hide-details
                        outlined
                        label="Team name"
                        :return-object="false"
                        :search-input.sync="searchTeam"
                        @change="searchTeam = null"
                    />
                </v-col>
                <!-- employee name -->
                <v-col md="2" sm="4" cols="12" class="pa-0 ms-sm-3 d-flex">
                    <v-autocomplete
                        v-model="name"
                        :items="employee"
                        item-value="name"
                        item-text="name"
                        :return-object="false"
                        label="name"
                        dense
                        hide-details
                        clearable
                        :disabled="loading"
                        outlined
                        prepend-icon="mdi-account-tie"
                    ></v-autocomplete>
                </v-col>
                
                <!-- <v-spacer /> -->
            
                <v-col cols="auto" sm="6" md="auto" class="d-flex justify-md-end px-0 pa-0">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        type="submit"
                        small
                    >
                        <v-icon> mdi-magnify </v-icon>
                        Search
                    </v-btn>
                    <v-btn
                        class="px-sm-8"
                        @click="clearFilter(); filter();"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        Reset
                    </v-btn>

                </v-col>
                <v-col>
                    <v-divider vertical />

                    <v-btn
                        small
                        color="green lighten-1 white--text"
                        @click="ImportExcel"
                        class="mx-1"
                        :loading="excelLoading"
                        :disabled="loading || excelLoading"
                    >
                        <v-icon> mdi-file-table-outline </v-icon>
                        Export excel
                    </v-btn>
                </v-col>
                  
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? filteredEmployees : []"
            pagination-on-scroll
            :loading="loading"
            dense
            fixed-header
            :height="$vuetify.breakpoint.height - 190"
            zebra
            class="mx-1 my-1"
        >
            <!-- Action -->
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-center align-center">
                    <!-- show employee details -->
                    <tooltip text="View employee details" top>
                        <v-btn
                            icon
                            color="success"
                            small
                            :to="
                                {
                                    name: 'medical-rep-personal-data',
                                    params: { id: item.id },
                                    query: { 
                                        employeeType: tab === 0 ? 'medicalRep' : 'supervisor',
                                        preview: true,
                                        role: tabRole
                                    }
                                }
                            "
                            class="me-2"
                        >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </tooltip>

                    <!-- edit employee -->
                    <tooltip v-if="0" text="Edit employee account" top>
                        <v-btn
                            icon
                            color="edit-color"
                            small
                            class="me-2"
                            @click="editEmployee(item.id)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip
                        text="View the medical rep is plan"
                        top
                    >
                        <v-btn 
                            v-if="
                            ((roles.length === 0 ? 0 : roles[tabRole].id === '860a6e41-1ee4-407f-fb89-08dc060389d0') && !firstLevel) || ((permissionsUser.MedicalPlans !== roleName.nothing &&item.employeeType === 1) && !firstLevel)"
                            color="blue" 
                            text 
                            small
                            :to="{
                                name: 'medical-rep-plan',
                                params: {
                                    id: item.id
                                }
                            }" 
                        >
                            <v-icon>mdi-calendar-month</v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip
                        text="View the administrative is plan"
                        top
                    >
                        <v-btn 
                            v-if="(permissionsUser.MedicalPlans !== roleName.nothing &&item.employeeType !== 1) && !firstLevel"
                            color="blue" 
                            text 
                            small
                            :to="{
                                name: 'administrative-plan',
                                params: {
                                    id: item.id
                                }
                            }" 
                        >
                            <v-icon>mdi-calendar-month</v-icon>
                        </v-btn>
                    </tooltip>
                    <!-- :disabled="employees >= 28" -->
                    <tooltip
                        text="Activate / Deactivate employee"
                        top
                    >
                        <v-switch
                            v-model="item.isDeactivated"
                            :true-value="false"
                            :false-value="true"
                            readonly
                            dense
                            hide-details
                            class="ma-0"
                            :disabled="item.isDeactivated ? isEmployeeActionsPrevented : false"
                            @click="activeToggleDialog(item)"
                        ></v-switch>
                    </tooltip>

                    <!-- delete employee -->
                    <tooltip
                        text="Delete employee"
                        top
                    >
                        <v-btn
                            icon
                            color="alert-color"
                            small
                            @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'medicalreps')"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
import DeleteDialog from '@/components/DeleteDialog'
import { mapState, mapGetters } from 'vuex'
import { gender, genders, userType, userTypes, roleName } from '@/helpers/enums'
import rules from '@/validation rules'
import moment from 'moment'
import { maximumUsers, firstLevel, roleNameArabic } from '@/configs/global.config'

export default {
    name: 'Staff',

    components:{
        Tooltip,
        DeleteDialog,
    },

    data(){
        return {
            teamIds: [],
            roleName,
            tabb: null,
            // filter Data
            name: null,
            cityId: [],
            regionIds: [],
            filteredEmployees: [],

            // helpers
            loading: false,
            submitLoading: false,
            regionsLoading: false,
            isFiltered: false,
            tab: 0,
            previewPhoto: null,

            gender,
            genders,
            userType,
            userTypes,
            rules,
            
            // employee dialog
            employeeId: null,
            sectorsLoading: false,

            employeeData:{
                name: null,
                birthDate: null,
                landlineNumber: null,
                phoneNumber: null,
                address: null,
                email: null,
                roleId: null,
                facebookProfileUrl: null,
                startingDate: null,
                username: null,
                password: null,
                userType: userType.medicalRep,
                gender: gender.male,
                targetValue: 0,
                lineManagerId: null,
                leaveDaysNumber: 12,
                specialtyIds: [],
                regionIds: [],
                cityId: [],
                sectorId: null,
                teamId: [],
                employeeType: 0,
                certificate: null 
            },
            medicalRepImagePath: null,
            employeePhoto: null,
            activePicker: null,
            jobPositions: [
                {
                    id: 1,
                    name: 'Medical rep'
                },

                {
                    id: 0,
                    name: 'Supervisor'
                },
                {
                    id: 2,
                    name: 'Admin'
                }
            ],
            tabRole: 0,
            showPassword: false,
            employee: [],
            medicalRepDialog: false,
            searchTeam: null,
            searchTeamDialog: null,
            searchCityIdDialog: null,
            searchRegionDialog: null,
            searchSpecialtyIdsDialog: null,

            activeEmployeesCount: 0,
            arra: [],
            lineManagers: [],
            managerDialog: false,
            directBriefEmployee: [],
            lineManagerId: null,
            userCreated: null,
            excelLoading: false,
            firstLevel,
            roleNameArabic
        }
    },

    watch: {
        'employeeData.userType'(val) {
            if (val === 1) {
                this.employeeData.roleId = this.roles?.find(c => c.name === 'Medical Rep')?.id
                this.employeeData.employeeType = userType.medicalRep
            } else {
                this.employeeData.roleId = null
                this.employeeData.employeeType = 0
            }
        }
    },

    computed:{
        myRoles () {
            let role = null
            if (roleNameArabic) {
                if (this.employeeData.userType === 1) {
                    role = this.roles.filter(c => c.name.includes('مندوب'))
                    console.log('2')
                } else {
                    role = this.roles.filter(c => !c.name.includes('مندوب'))
                }
            } else {
                console.log('1')
                if (this.employeeData.userType === 1) {
                    role = this.roles.filter(c => c.name === 'Medical Rep\r\n')
                } else {
                    role = this.roles.filter(c => c.name !== 'Medical Rep\r\n')
                }
            }
            return role
        },

        isAllFieldsEmpty(){
            return !this.name && !this.regionIds.length && !this.teamIds.length;
        },

        ...mapState({
            cities: state => state.cities.cities,
            sectorCities: state => state.sectors.sectorCities.sectors,
            regions: state => state.regions.regions,
            medicalReps: state => state.medicalreps.medicalreps,
            supervisors: state => state.supervisors.supervisors,
            sectors: state => state.sectors.sectors,
            sectorsFiltered: state => state.sectors.sectorsFiltered,
            permissionsUser: state => state.auth.youPermissions,
            roles: state => state.rolePermission.roles,
            specialties: state => state.specialties.specialties,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            regionsAll: state => state.regions.regionsAll,
            citiesBySectorFilter: state => state.sectors.citiesBySectorFilter,
            employeesInRoles: state => state.rolePermission.employeesInRoles,
            teams: state => state.teams.teams,
            employees: state => state.medicalreps.employees,
            userName: state => state.auth.userData.employeeId,
            sectorsMine: state => state.sectors.sectorsMine,
            userLogin: state => state.auth.self.employeeId
        }),

        ...mapGetters({
            getSectorById:'sectors/getSectorById',
            getRegionById:'regions/getRegionById',
            getCityById:'cities/getCityById',
            getCityNameBySectorId: 'sectors/getCityNameBySectorId',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getSupervisorById: 'supervisors/getSupervisorById',
            getRoleById: 'rolePermission/getRoleById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            getEmployeeForRolesById: 'rolePermission/getEmployeeForRolesById',
            getTeamById: 'teams/getTeamById',
            getRegionAllById: 'regions/getRegionAllById',
            isAdmin: 'auth/isAdmin'
        }),

        headers() {
            const headers = [
                { 
                    text: 'Name',
                    name: 'name',  
                    value: 'name',  
                    class:'primary--text', 
                    cellClass:'primary--text', 
                    width: 200
                },
                { 
                    text: 'Team', 
                    name: 'teamId',
                    value: 'teamId',
                    setValueExpr: val => val.map(c => this.getTeamById(c)?.name).join(', '),
                    align: 'center',
                    width: 100,
                    noNotAvailableText: true
                },
                { 
                    text: 'Phone Number',
                    value: 'phoneNumber', 
                    align: 'center',
                },
                { 
                    text: 'specialties',
                    name: 'specialtyIds',
                    value: 'specialtyIds',
                    setValueExpr: val => val.map(c => this.getSpecialtyById(c)?.name).join(','), 
                    align: 'center',
                },
                { 
                    text: 'Work Period', 
                    value: 'startingDate', 
                    setValueExpr: val => val ? moment(val, "YYYY-MM-DD").fromNow().replace('ago', '') : '-',
                    align: 'center',
                    noNotAvailableText: true
                },
                { 
                    text: 'City', 
                    value: 'regionIds', 
                    setValueExpr: val =>
                        val.length > 1
                            ? val.map(c => this.getCityById(this.getRegionAllById(c)?.cityId)?.name)
                                .filter((name, index, arr) => arr.indexOf(name) === index).join(' ,')
                            : this.getCityById(this.getRegionAllById(val[0])?.cityId)?.name,
                    align: 'center',
                }
            ];
            if (this.isSuperAdmin || this.permissionsUser.Roles !== roleName.nothing) {
                headers.push({ 
                    text: 'Role',
                    value: 'roleId', 
                    align: 'center',
                    setValueExpr: val => this.getRoleById(val)?.name
                })
            }
            if (this.isSuperAdmin || this.permissionsUser.MedicalReps === roleName.action) {
                headers.push({ 
                    text: 'Actions', 
                    name: 'action', 
                    value: 'action', 
                    align: 'center', 
                    sortable: false,
                    width: 160
                })
            }

            if (this.tab === 1) headers.splice(3, 1);

            const sharedClass = 'text-body-2 font-weight-bold px-2';
            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
                headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
            }

            return headers;
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },

        selectedAllCitiesDialog () {
            return this.employeeData.cityId.length === this.cities.length;
        },

        selectedAllRegions () {
            return this.employeeData.regionIds.length === this.regionsAll.filter(c => this.employeeData.cityId?.includes(c.cityId)).length;
        },

        selectedAllRegionsFilter () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

        iconRegionsFilter() {
            if (this.selectedAllRegionsFilter) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconCitiesDialog() {
            if (this.selectedAllCitiesDialog) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
        
        selectedAllSpecialties () {
            return this.employeeData.specialtyIds.length === this.specialties.length;
        },

        iconSpecialty() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        isEmployeeActionsPrevented() {
            return maximumUsers.active && this.activeEmployeesCount >= maximumUsers.maxUsers;
        },
    },

    methods:{
        toggleSpecialty () {
            this.$nextTick(() => {
                if (this.selectedAllSpecialties) {
                    this.employeeData.specialtyIds = []
                } 
                else {
                    this.employeeData.specialtyIds = this.specialties.map(c => c.id);
                    this.employeeData.specialtyIds.forEach(specialty => {
                    if (this.employeeData.specialtyIds.includes(specialty.id)) {
                        this.employeeData.specialtyIds.push(specialty.id);
                    }
                });  
                }
            })
        },

        toggleRegion() {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.regionIds = []
                    this.employeeData.regionIds = this.regionsAll.filter(c => this.employeeData.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        toggleRegionFilter() {
            this.$nextTick(() => {
                if (this.selectedAllRegionsFilter) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);  
                }
            })
        },

        // select all inputs
        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCity()
                }
            })
        },

        toggleCitiesDialog () {
            this.employeeData.regionIds = []
            this.$nextTick(() => {
                if (this.selectedAllCitiesDialog) {
                    this.employeeData.cityId = []
                    this.employeeData.regionIds = []
                } 
                else {
                    this.employeeData.cityId = this.cities.map(c => c.id);
                }
            })
        },

        filter(isFirstLoad = false){
            this.loading = true;

            if (this.permissionsUser.Roles === roleName.nothing) {
                return this.$store.dispatch('medicalreps/fetchByFilter', {
                    name: this.name,
                    regionIds: this.regionIds,
                    teamIds: this.teamIds
                }).then((data) => {
                    this.filteredEmployees = data;
                }).finally(() => {
                    if (!isFirstLoad) this.loading = false;
                })
            } else {
                this.getEmployeeForRole(this.roles[this.tabRole].id)
            }
        },

        getEmployeeById(employeeId) {
            return this.filteredEmployees.find(c => c.id === employeeId)
        },

        // Filter functions
        fetchRegionsDueCity(){
            this.regionIds = [];
            if (!this.cityId) return;
            
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

        clearFilter(){
            this.cityId = [];
            this.name = null;
            this.regionIds = [];
            this.teamIds = [];

            this.isFiltered = false;
        },

        // dialog employee methods
        resetInputs() {
            if (this.$refs.form) this.$refs.form.resetValidation();
            this.employeeData.name = null;
            this.employeeData.certificate = null;
            this.employeeData.birthDate = null;
            this.employeeData.landlineNumber = null;
            this.employeeData.phoneNumber = null;
            this.employeeData.regionIds = [];
            this.employeeData.address = null;
            this.employeeData.email = null;
            this.employeeData.roleId = null;
            this.employeeData.facebookProfileUrl = null;
            this.employeeData.startingDate = null;
            this.employeeData.username = null;
            this.employeeData.password = null;
            this.employeeData.userType = 0;
            this.employeeData.gender = gender.male;
            this.employeeData.targetValue = 0;
            this.employeeData.lineManagerId = null;
            this.employeeData.leaveDaysNumber = 12;
            this.employeeData.specialtyIds = [];
            this.previewPhoto = null;
            this.employeePhoto = null;
            this.employeeData.teamId = []
        },

        addNewMedicalRep(){
            this.employeeId = null;
            this.employeeData.cityId = [];

            this.resetInputs();

            this.medicalRepDialog = true;
        },

        cityIdChange(){
            this.sectorsLoading = true;
            this.$store.dispatch('regions/fetchAll', { cityId: this.employeeData.cityId })
            .finally(() => {
                this.sectorsLoading = false;
            });
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;

                const moduleName = this.employeeData.userType === userType.medicalRep ? 'medicalreps' : 'supervisors'
                const actionName = this.employeeId ? 'update' : 'create';
                
                this.$store.dispatch(`medicalreps/${actionName}`, {
                    id: this.employeeId,
                    data: { 
                        ...this.employeeData, 
                        employeeType: this.employeeData.employeeType, 
                        lineManagerId: this.userLogin,
                        specialtyIds: this.employeeData.teamId.length !== 0 ? this.employeeData.teamId.map(c => this.getTeamById(c).specialtyIds).toString().split(',').filter(c => c !== '') : this.employeeData.specialtyIds
                    }
                }).then((data) => {
                    const message =
                        (this.employeeData.userType === userType.medicalRep ? 'Medical rep' : 'Employee') + 
                        ' is added';
                    
                    this.$eventBus.$emit('show-snackbar', message, 'info');
                    this.filter();
                    
                    // BUG: need to get id after adding or editing an employee then update the target
                    /* // TODO remove the comment to set target after fixing the BUG
                    if (this.employeeData.userType === userType.medicalRep) {
                        this.$store.dispatch('medicalrepTarget/update', { 
                            id: data[0].id, 
                            value: this.employeeData.targetValue 
                        })
                    }
                    */
                    this.userCreated = data.data
                    if (this.employeeData.userType !== userType.medicalRep) {
                        this.$store.dispatch('medicalreps/fetchEmployeeLineManager', { EmployeeId: data.data }).then((data) => {
                        this.lineManagers = data
                        this.$store.dispatch('medicalreps/fetchEmployeeDirectBrief', {
                                employeeId: this.$route.params.id,
                                roleId: this.employeeData.roleId
                            }).then((data) => {
                                this.directBriefEmployee = data.map(c => ({ ...c, model: null }))
                                this.managerDialog = true
                                this.medicalRepDialog = false;
                                this.submitLoading = false;
                            }).catch(() => {
                                this.medicalRepDialog = false;
                                this.submitLoading = false;
                            })
                        })
                    } else {
                        this.medicalRepDialog = false;
                        this.submitLoading = false;
                    }
                    this.resetInputs();
                }).catch(() => {
                    this.submitLoading = false;
                })
            }
        },

        // function toggle dialog
        submitToggle(){
            this.submitLoading = true;

            this.$store.dispatch('medicalreps/toggle', { id: this.employeeId })
            .then(() => {
                this.$refs.dialogToggle.isActive = false;
                this.$eventBus.$emit(
                    'show-snackbar',
                    'Employee account status has been toggled successfully.',
                    'info'
                );
                // this.updateActiveEmployees();
                this.filter();
            })
            .finally(() => {
                this.submitLoading = false;
            })
        },
        onImageChange() {
            if (this.employeePhoto) {
                const reader = new FileReader();
                reader.readAsDataURL(this.employeePhoto);
                reader.onload = e => { this.previewPhoto = e.target.result || this.employeePhoto; }
            }
        },

        getEmployeeForRole(id, isFirstLoad = false) {
            this.loading = true
            return this.$store.dispatch('rolePermission/fetchRoleEmployee', { 
                id: id,
                name: this.name, 
                regionIds: this.regionIds, 
                teamIds: this.teamIds,
                nonDeleted: true 
            }).then((data) => {
                this.filteredEmployees = data.data
                this.employee = data.data
                this.$router.push({ 
                    query: {
                        role: this.tabRole
                    }
                })
            }).finally(() => {
                if (!isFirstLoad) this.loading = false
            })
        },

        generate() {
            this.employeeData.username = this.employeeData.name.toLowerCase().replaceAll(' ', '-');
            const alphabet = 'abcdefghijklmnobqrstuvwxyz';
            const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
            const randomLetterTwo = alphabet[Math.floor(Math.random() * 2)];
            this.employeeData.password = randomLetter + randomLetterTwo + Date.now().toString().slice(Math.round(Math.random()) + 4, -4)
        },

        getCities() {
            this.$store.dispatch('sectors/fetchCitiesInSector', { id: this.employeeData.sectorId })
        },

        activeToggleDialog(item) {
            if (this.isEmployeeActionsPrevented && item.isDeactivated) return;
            this.employeeId = item.id;
            this.$refs.dialogToggle.isActive = true;
        },

        // updateActiveEmployees() {
        //     return this.$store.dispatch('medicalreps/fetchEmployeeCount').then(data => {
        //         this.activeEmployeesCount = data;
        //     })
        // }

        updateLineManager () {
            this.submitLoading = true
            Promise.all([
                this.$store.dispatch('medicalreps/updateLineManager', {
                    newLineManagerId: this.lineManagerId,
                    employees: [this.userCreated] 
                }),
                this.$store.dispatch('medicalreps/updateLineManager', {
                    newLineManagerId: this.userCreated,
                    employees: this.directBriefEmployee.map(c => c.model).filter(c => c !== null)
                })
            ]).then(() => {
                this.submitLoading = false
                this.managerDialog = false
            }).catch(() => {
                this.submitLoading = false
            })
        },
        ImportExcel() {
            this.excelLoading = true
            this.$store.dispatch('exporter/fetchStaff', { 
                id: this.roles[this.tabRole].id,
                name: this.name,
                regionIds: this.regionIds.length ? this.regionIds : [],
                teamIds: this.teamIds.length ? this.teamIds : []
            }).finally(() => {
                this.excelLoading = false
            })
        },
    },

    created(){
        this.loading = true;
        this.tabRole = this.$route.query.role ? +this.$route.query.role : 0
        this.employeeData.lineManagerId = this.userName
        
        Promise.all([
            // this.updateActiveEmployees(),
            !this.teams.length ? this.$store.dispatch('teams/fetchAll') : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
            !this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {}) : null,
            (this.permissionsUser.Roles === roleName.nothing && !this.isAdmin)
                ? this.$store.dispatch('medicalreps/fetchAll', true).then((data) => {
                    this.filteredEmployees = data
                    this.employee = data
                })
                : null,
            this.$store.dispatch('specialties/fetchAll'),
            !this.sectorsMine.length ? this.$store.dispatch('sectors/getSectorsMine') : null,
            this.permissionsUser.Roles !== roleName.nothing
                ? (async () => {
                    await this.$store.dispatch('rolePermission/fetchChildrenRole', { id: this.userName });
                    if (this.roles.length !== 0) {
                        await this.getEmployeeForRole(
                            this.$route.query.role ? this.roles[this.$route.query.role].id : this.roles[0].id,
                            true
                        );
                    }
                })()
                : null,
        ]).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Staff',
        titleTemplate: '%s | Octopharma'
    },
}
</script>